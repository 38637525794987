import pick from 'lodash/pick'

export default class SupportTicket {
  constructor() {
    this.title = ''
    this.body = ''
    this.priority = 2,
    this.type = '',
    this.current_status = {}
    this.comments = []
    this.participants = []
    this.company = {}
    this.user = {}
    this.resolved_by = {}
    this.files = [],
    this.file = ''
  }

  get payload() {
    return pick(this, [
      'title',
      'body',
      'reference',
      'priority',
      'file',
      'type'
    ])
  }
}
