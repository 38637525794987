import User from './User'
import { classes } from '@/mixins/js-helpers'

export default class RecurringJob extends classes.BaseModel {

  constructor() {
    super()
    this.reference = ''
    this.technician_id = ''
    this.job_type_id = ''
    this.site_id = ''
    this.technician = new User
    this.user = new User
    this.type = {}
    this.site = {
      client: {},
      zones: []
    }
    this.client = {}
    this.start_date = ''
    this.end_date = ''
    this.allow_early_closure = true
    this.frequency = {
      interval: 'weekly',
      exclusions: [],
      exclusion_rule: 'discard',
    }
    this.jobs = []
    this.zones = []
    this.open_first_job_immediately = false
    this.reference = ''
  }

  get payload() {
    return {
      ...this,
      frequency: {
        ...this.frequency,
        exclusions: this.frequency.exclusions.map(exclusion => exclusion.value)
      }
    }
  }

}
