export default class UserProfile
{
    constructor() {
      this.user_id = ''
      this.dialing_code = ''
      this.mobile = ''
      this.formatted_mobile = ''
      this.telephone = ''
      this.picture
      this.position = ''
    }
}