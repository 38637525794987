import User from './User'
import RecurringJob from './RecurringJob'
import { classes } from '@/mixins/js-helpers'

export default class ScheduledJob extends classes.BaseModel {

  constructor() {
    super()
    this.hydrate({
      technician: new User,
      recurring_job: new RecurringJob,
    })
  }

}
