import SensorType from '@/models/SensorType'
import Asset from '@/models/Asset'

export default class Sensor {
  constructor () {
    this.source_id = ''
    this.type = new SensorType
    this.asset = new Asset
    this.name = ''
    this.description = ''
  }
}