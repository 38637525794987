export default class Subscription {

  constructor() {
    this.trial_ends_at = ''
    this.active = false
    this.billing_method = ''
    this.billing_email = ''
  }

}
